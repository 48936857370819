/* eslint-disable eqeqeq */
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import Header from "components/Headers/Header";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "services/httpServices";
import { getUserId } from "services/httpServices";
import { btnCss, iconCss } from "../../appConfig";
import SearchableSelect from "components/dropdown/SearchDropdown";
import moment from "moment";
import { postMethod } from "services/httpServices";
import ReactPaginate from "react-paginate";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { getAmt } from "services/util";
import autoTable from "jspdf-autotable";
import Logo from "../../assets/img/brand/project_logo.png"
import { getUserName } from "services/httpServices";

const sortIS = {
  sortPOSID: 0,
  sortRegisteredDate: 0,
  sortRegisteredLocation: 0,
  sortTodaysSales: 0,
  sortMtdSales: 0,
  sortYtdSales: 0,
};

function Statements() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(sortIS);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [posDeviceData, setPosDeviceData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [users, setUsers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [statements, setStatements] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const [xlLoading, setXlLoading] = React.useState(false);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };
  function fetchPosDeviceData() {
    getMethod("/api/POS/GetAllDevice")
      .then((res) => {
        if (res.data?.length) {
          setPosDeviceData(res.data);
        }
      })
      .catch(console.log);
  }

  function fetchAllUsers() {
    getMethod(`/api/User/merchant/all?merchantUserId=${getUserId()}`)
      .then((res) => {
        if (res.data?.result?.length) {
          setAllUsers(res.data.result);
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    fetchPosDeviceData();
    fetchAllUsers();
  }, []);

  const sortHandle = (fieldValue, fieldName, sortBy) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSortBy(sortBy);
  };

  const getAllData = () => {
    setLoading(true);
    postMethod(
      "/api/MerchantProfile/GetTransactionDetails",
      {
        merchantId: getUserId(),
        startDate: startDate,
        endDate: endDate,
        posMacIds: devices,
        posAgentUseIds: users,
      },
      "post"
    )
      .then((res) => {
        if (res?.data?.result?.length) {
          setStatements(res.data?.result);
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  };

  let filteredData = statements.sort((a, b) => {
    if (sortDirection === "asc") {
      return typeof a[sortBy] === "string"
        ? String(a[sortBy]).localeCompare(String(b[sortBy]))
        : a[sortBy] - b[sortBy];
    } else if (sortDirection === "desc") {
      return typeof a[sortBy] === "string"
        ? String(b[sortBy]).localeCompare(String(a[sortBy]))
        : b[sortBy] - a[sortBy];
    } else {
      return 0;
    }
  });

  if (searchText) {
    filteredData = filteredData.filter((item) =>
      String(item?.tellerName).includes(searchText?.trim())
    );
  }

  const downloadPDF = (data, fields, fileName, extraInfo) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape
  
    const doc = new jsPDF(orientation, unit, size);
    
    // Add logo
    const logoUrl = Logo;
    doc.addImage(logoUrl, "PNG", 10, 10, 30, 30);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Smartpay", 40, 32); // Position the company name beside the logo
    
    // Add border below the logo
    doc.setDrawColor(238); // #eee in RGB
    doc.setLineWidth(0.5);
    doc.line(10, 45, 585, 45); // x1, y1, x2, y2
    
    // Add centered document title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    const titleWidth = doc.getTextWidth(fileName);
    const pageWidth = doc.internal.pageSize.width;
    const centerX = (pageWidth - titleWidth) / 2;
    doc.text(fileName, centerX, 65); // Center text horizontally
  
    // Add extra info below title
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
  
    const infoStartY = 80; // Start position below the title
    let lineSpacing = 15; // Spacing between lines
    let currentY = infoStartY;
  
    const extraFields = [
      // `Title: ${extraInfo.title ?? "-"}`,
      `Start Date: ${extraInfo?.startDate ? new Date(extraInfo.startDate).toLocaleString() : "-"}`,
      `End Date: ${extraInfo?.endDate ? new Date(extraInfo.endDate).toLocaleString() : "-"}`,
      `Merchant Name: ${extraInfo?.merchat ?? "-"}`,
      `User: ${extraInfo?.username ?? "-"}`,
      `Device: ${extraInfo?.posId ?? "-"}`,
    ];
  
    extraFields.forEach((field) => {
    doc.setFontSize(8);
      doc.text(field, 10, currentY);
      currentY += lineSpacing;
    });
  
    // Adjust table position based on extra fields
    const tableStartY = currentY + 10;
  
    autoTable(doc, {
      head: fields,
      body: data,
      startY: tableStartY-10,
      theme: "striped",
      styles: {
        fillColor: "#ffffff",
        textColor: "#000000",
        fontSize: 8,
      },
      headStyles: {
        fillColor: "#023f8a",
        textColor: "#ffffff",
        fontSize: 8,
        fontStyle: "bold",
      },
      margin: { top: 5, left: 10, right: 10 },
      didDrawPage: () => {
        // Add timestamp and page numbers at the bottom of each page
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
  
        // Timestamp
        const timestamp = `Generated on: ${new Date().toLocaleString()}`;
        doc.setFontSize(8);
        doc.setFont("helvetica", "normal");
        doc.text(timestamp, 10, pageHeight - 10); // Bottom-left corner
  
        // Page numbers
        const pageNumber = `Page ${doc.getNumberOfPages()}`;
        const textWidth = doc.getTextWidth(pageNumber);
        doc.text(pageNumber, pageWidth - textWidth - 10, pageHeight - 10); // Bottom-right corner
      },
    });
  
    doc.save(`${fileName}.pdf`);
    setPdfLoading(false);
  };
  

  const exportPDF = async () => {
    const allData = statements;
    setPdfLoading(true);
    let headers = [
      [
        "Transaction Time/Date",
        "Transaction Ref",
        "Amount (Le)",
        "Customer",
        "Teller",
        "Location",
      ],
    ];

    let data = allData.map((teller) => [
      new Date(teller.createdDate).toLocaleString(),
      teller.transactionId ?? "-",
      getAmt(teller.amount) ?? "-",
      teller.fromPaymentName ?? "-",
      teller.collectedBy ?? "-",
      teller.location ?? "-",
    ]);
    console.log({allUsers, users})
    const extra =  {
      merchat: getUserName(),
      startDate: startDate,
      endDate: endDate,
      posId: devices?.toString(),
      username: allUsers.find(i => i.userId === users?.[0])?.fullName ?? "-"
    }
    downloadPDF(data,headers, "Transactions", extra)
  };

  const exportToXlsx = async (reportData) => {
    const allData = statements;

    const wb = XLSX.utils.book_new();
    let data = allData.map((mmo) => {
      return {
        "Transaction Time/Date": new Date(mmo.createdDate).toLocaleString(),
        "Transaction Ref": mmo.transactionId ?? "-",
        "Amount (Le)": getAmt(mmo.amount) ?? "-",
        Customer: mmo.fromPaymentName ?? "-",
        Teller: mmo.collectedBy ?? "-",
        Location: mmo.location ?? "-",
      };
    });

    let headings = [
      "Transaction Time/Date",
      "Transaction Ref",
      "Amount (Le)",
      "Customer",
      "Teller",
      "Location",
    ];

    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Set up column headers
    ws["!cols"] = headings.map((heading) => ({ wch: heading.length + 5 }));

    // Export the workbook to a file
    setTimeout(() => {
      setXlLoading(false);
    }, 1000);
    XLSX.writeFile(wb, "report.xlsx");
  };
  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex justify-content-start  align-items-center">
            <div>
              <h5>Start Date</h5>
              <div class="dropdown-border">
                <Input
                  type="date"
                  id="status"
                  className="form-control-alternative status-filter"
                  name="startDate"
                  placeholder="Start Date"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Input>
              </div>
            </div>
            <div className="ml-1">
              <h5>End Date</h5>
              <div class="dropdown-border">
                <Input
                  type="date"
                  id="status"
                  className="form-control-alternative status-filter"
                  name="endDate"
                  placeholder="End Date"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Input>
              </div>
            </div>
            <div className="ml-2">
              <h5>Select User</h5>
              <SearchableSelect
                options={allUsers?.map((i) => ({
                  key: i.userId,
                  value: i.fullName,
                }))}
                onSelect={(e) => setUsers([...users, e.key])}
              />
            </div>
            <div className="ml-2">
              <h5>Select POS</h5>
              <SearchableSelect
                options={posDeviceData?.map((i) => ({
                  key: i.posSerialNumber,
                  value: i.posDeviceName,
                }))}
                onSelect={(e) => setDevices([...devices, e.key])}
              />
            </div>
            <div className="mt-4 ml-2">
              <Button onClick={getAllData} color="primary">Submit</Button>
              {filteredData.length > 0 && (
              <>
                <Button
                  color="primary"
                  onClick={exportPDF}
                  disabled={pdfLoading}
                >
                  Download PDF
                  {pdfLoading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
                <Button
                  color="primary"
                  onClick={exportToXlsx}
                  disabled={xlLoading}
                >
                  Download XLSX
                  {xlLoading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </>
            )}
            </div>

          </CardHeader>
          {/* <CardHeader className="border-0 d-flex justify-content-end align-items-center">
            <FormGroup className="mb-0 navbar-search ">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="search-cls"
                  placeholder="Search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <Button
              color="primary"
              onClick={fetchData}
              size="sm"
              style={btnCss}
              data-toggle="tooltip"
              title="Refresh"
            >
              <i class="fas fa-redo fa-lg" style={iconCss}></i>
            </Button>
          </CardHeader> */}
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Transaction Time/Date</th>
                <th scope="col">Transaction Ref </th>
                <th scope="col">Amount (Le)</th>
                <th scope="col">Customer</th>
                <th scope="col">Teller</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData
                  .slice(currentPage * 10, (currentPage + 1) * 10)
                  .map((teller, i) => {
                    return (
                      <tr>
                        <th scope="row">
                          {new Date(teller.createdDate).toLocaleString() ?? "-"}
                        </th>
                        <td>{teller.transactionId ?? "-"}</td>
                        <td>{teller.amount ?? "-"}</td>
                        <td>{teller.fromPaymentName ?? "-"}</td>
                        <td>{teller.collectedBy ?? "-"}</td>
                        <td>{teller.ytdSales ?? "-"}</td>
                      </tr>
                    );
                  })}
            </tbody>
            {loading && <FullPageLoader label={"Fetching..."} />}
            {!loading && !statements.length && <NoRecordFound />}
          </Table>
          <CardFooter className="py-4">
            {filteredData.length > 0 && (
              <Row>
                <Col lg="6">
                  <div>
                    <small>
                      <strong>Total Records : {filteredData.length}</strong>
                    </small>
                  </div>
                </Col>

                <Col lg="6">
                  <nav aria-label="...">
                    <div className="float-right">
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(filteredData.length / 10)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"paginationV2"}
                        subContainerClassName={"pages paginationV2"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                      />
                    </div>
                  </nav>
                </Col>
              </Row>
            )}
          </CardFooter>{" "}
        </Card>
      </Container>
    </div>
  );
}

export default Statements;
